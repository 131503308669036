import request from "../../utils/request"

// 创建小区
export const createVillage = (data: any) => request.post("/buildVillage", data)

// 获取小区列表
export const findlVillageList = () => request.get("/findAllVillage")

// 删除小区
export const deleteVillage = (id) =>
  request.get("/erasureVillage" + `?id=${id}`)

// 创建学校
export const createSchool = (data: any) => request.post("/buildSchool", data)

// 创建学院
export const createCollege = (data: any) => request.post("/buildCollege", data)

// 获取学校列表
export const findSchoolList = () => request.get("/findAllSchoolIncludeCollege")

// 删除学院
export const deleteCollege = (id) =>
  request.get("/erasureCollege" + `?id=${id}`)
// 删除学院
export const deleteSchool = (id) =>
    request.get("/deleteSchool" + `?id=${id}`)