import { defineStore } from "pinia";
import { reqLogin, reqUserInfo } from "../api/user";
import type { userInfo } from "../types/user";

interface ObjectList {
  [keys: string]: string[];
}

export const usePermissStore = defineStore("permiss", {
  state: () => {
    const key = localStorage.getItem("ms_keys");
    return {
      userInfo: <userInfo>{},
      uid: "",
      keys: key ? JSON.parse(key) : [],
      defaultList: <ObjectList>{
        super: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "61",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          '21',
        ],
        admin: [
          "1",
          "2",
          "3",
          "4",
          "5",
          "6",
          "7",
          "8",
          "61",
          "9",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          '21',
        ],
        store: ['2', '22', "9", "12", "10", "6", "61",],
        user: ["2", "3", "11", "13", "14", "15"],
      },
      token: localStorage.getItem("TOKEN"),
      shopid: localStorage.getItem("SHOPID"),
      power: localStorage.getItem("POWER"),
      uname: localStorage.getItem("UNAME") || '用户007'
    };
  },
  actions: {
    handleSet(val: string[]) {
      this.keys = val;
    },
    async userLogin(data) {
      let result: any = await reqLogin(data);
      if (result.code == 200) {
        this.token = result.data.wtoken;
        this.uid = result.data.uid;
        localStorage.setItem("TOKEN", result.data.wtoken);
      } else {
        return Promise.reject(new Error(result.msg));
      }
    },

    async getUserInfo() {
      let result: any = await reqUserInfo(this.uid);
      if (result.code == 200) {
        this.userInfo = result.data;
        let power;

        if (result.data.superflg == "00") {
          if (result.data.storeflg == "00") {
            this.keys = [...this.defaultList["super"], '22'];
          } else {
            this.keys = this.defaultList["super"];
          }
          power = "super"
        } else if (result.data.adminflg == "00") {
          if (result.data.storeflg == "00") {
            this.keys = [this.defaultList["admin"], '22'];
          } else {
            this.keys = this.defaultList["admin"];
          }
          power = "admin"
        } else if (result.data.storeflg == "00") {
          this.keys = this.defaultList["store"];
          this.shopid = result.data.shopid
          power = "store"
          localStorage.setItem("SHOPID", result.data.shopid);
        } else {
          this.keys = this.defaultList["user"];
          power = 'user'
        }
        this.power = power
        this.uname = result.data.uname || result.data.wxname
        localStorage.setItem("POWER", power);
        localStorage.setItem("UNAME", result.data.uname || result.data.wxname);
        localStorage.setItem("ms_keys", JSON.stringify(this.keys));
      }
    },
  },
});
