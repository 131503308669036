<template>
  <div class="footer">
    Copyright © 2024 力峰宿达 -
    <a href="https://beian.miit.gov.cn/" target="_blank"> 苏ICP备2024087970号-1</a>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref, computed } from "vue";

onMounted(() => {});
</script>
<style scoped>
.footer {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #242f42;
  color: #efefef;
  z-index: 100;
}
a {
  font-size: 0.85rem;
  color: #efefef;
}
</style>
