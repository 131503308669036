import { defineStore } from "pinia";
import { getSysGoodsType } from "../api/goods";
import { findSchoolList } from "../api/school";

export const usePublicStore = defineStore("publicData", {
  state: () => {
    return {
      goodsTyppeList: [],
      schoolList: [],
      schoolSelect: []
    };
  },
  actions: {
    // 获取商品类型
    async getSysGoodsType() {
      let result: any = await getSysGoodsType();
      if (result.code == 200) {
        this.goodsTyppeList = result.data.list;
      } else {
        return Promise.reject(new Error(result.msg));
      }
    },
    // 获取学校列表
    async getAllSchool() {
      let result: any = await findSchoolList();
      if (result.code == 200) {
        this.schoolList = result.data.list;
        let selectarr = [];
        result.data.list.map(item => {
          if (item.shops && item.shops.length) {
            let arr: any = [];
            let obj = {
              value: '',
              label: '',
              children: []
            };
            obj.value = item.id;
            obj.label = item.school;
            item.shops.map((items) => {
              let objitem = {
                value: '',
                label: '',
              };
              objitem.value = items.shopid;
              objitem.label = items.shopname;
              arr.push(objitem);
            });
            obj.children = arr;
            selectarr.push(obj);
          }
        })
        this.schoolSelect = selectarr
      } else {
        return Promise.reject(new Error(result.msg));
      }
    },

    
  },
});
