import axios, {
  AxiosInstance,
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios"
import { ElMessage } from "element-plus"
import { usePermissStore } from "../store/permiss"
// 创建实例，配置请求
const service: AxiosInstance = axios.create({
  timeout: 5000,
})
// service添加请求拦截器
service.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    // 打包修改
    // config.url = "/lfsd" + config.url
    // 返回配置对象
    config.headers["Content-Type"] = "application/x-www-form-urlencoded"
    const permiss = usePermissStore()
    if (!!permiss.token) {
      config.headers.Authorization = permiss.token
    }
    return config
  },
  (error: AxiosError) => {
    return Promise.reject()
  }
)
// service添加响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    console.log(response)
    if (response.status === 200) {
      if (response.data.code == 200) {
        return response.data
      } else {
        ElMessage({
          type: "error",
          message: response.data.msg,
        })
        return response.data
      }
    } else {
      Promise.reject()
    }
  },
  (error: AxiosError) => {
    console.log(error)
    let message = ""
    let status = error.response.status
    switch (status) {
      case 401:
        message = "token过期"
        break
      case 403:
        message = "无权访问"
        break
      case 404:
        message = "请求地址出错"
        break
      case 500:
        message = "服务器出现问题"
        break
      case 606:
        message = "仓库库存不足，请及时补充库存"
        break
      default:
        message = "网络出现问题"
        break
    }
    ElMessage({
      type: "error",
      message,
    })
    return Promise.reject()
  }
)
export default service
