import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import { usePermissStore } from "../store/permiss"
import Home from "../views/home.vue"
import NProgress from "nprogress"
import "nprogress/nprogress.css"

const routes: RouteRecordRaw[] = [
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "系统首页",
          permiss: "1",
        },
        component: () => import("../views/dashboard.vue"),
      },
      {
        path: "/schoolorVillage",
        name: "SchoolorVillage",
        meta: {
          title: "学校/小区",
          permiss: "1",
        },
        component: () => import("../views/schoolorVillage.vue"),
      },
      {
        path: "/goods-table",
        name: "goodstable",
        meta: {
          title: "商品列表",
          permiss: "21",
        },
        component: () => import("../views/goods-table.vue"),
      },
      {
        path: "/supply-goods",
        name: "supplygoods",
        meta: {
          title: "补货",
          permiss: "22",
        },
        component: () => import("../views/supply-goods.vue"),
      },
      {
        path: "/member",
        name: "member",
        meta: {
          title: "用户列表",
          permiss: "6",
        },
        component: () => import("../views/member.vue"),
      },
      {
        path: "/administrator",
        name: "administrator",
        meta: {
          title: "管理员列表",
          permiss: "7",
        },
        component: () => import("../views/administrator.vue"),
      },
      {
        path: "/store-manager",
        name: "storemanager",
        meta: {
          title: "店长列表",
          permiss: "8",
        },
        component: () => import("../views/store-manager.vue"),
      },
      {
        path: "/store-user",
        name: "storeuser",
        meta: {
          title: "店员列表",
          permiss: "61",
        },
        component: () => import("../views/store-user.vue"),
      },
      {
        path: "/store-management",
        name: "storemanagement",
        meta: {
          title: "店铺管理",
          permiss: "9",
        },
        component: () =>
          import(
            /* webpackChunkName: "form" */ "../views/store-management.vue"
          ),
      },
      {
        path: "/storeInfo",
        name: "storeInfo",
        meta: {
          title: "店铺详情",
          permiss: "12",
        },
        component: () =>
          import(/* webpackChunkName: "form" */ "../views/storeInfo.vue"),
      },
      {
        path: "/orderinfo",
        name: "orderinfo",
        meta: {
          title: "订单详情",
          permiss: "10",
        },
        component: () =>
          import(/* webpackChunkName: "form" */ "../views/orderinfo.vue"),
      },
      {
        path: "/doings",
        name: "doings",
        meta: {
          title: "活动管理",
          permiss: "11",
        },
        component: () =>
          import(/* webpackChunkName: "form" */ "../views/doings.vue"),
      },
      {
        path: "/handle-message",
        name: "handlemessage",
        meta: {
          title: "待办详情",
          permiss: "12",
        },
        component: () => import("../views/handle-message.vue"),
      },

      {
        path: "/user",
        name: "user",
        meta: {
          title: "个人中心",
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "../views/user.vue"),
      },

      {
        path: "/export",
        name: "export",
        meta: {
          title: "导出Excel",
          permiss: "2",
        },
        component: () =>
          import(/* webpackChunkName: "export" */ "../views/export.vue"),
      },
      {
        path: "/tabs",
        name: "tabs",
        meta: {
          title: "消息",
          permiss: "3",
        },
        component: () =>
          import(/* webpackChunkName: "tabs" */ "../views/tabs.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "登录",
    },
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/login.vue"),
  },
  {
    path: "/403",
    name: "403",
    meta: {
      title: "没有权限",
    },
    component: () => import(/* webpackChunkName: "403" */ "../views/403.vue"),
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach((to, from, next) => {
  NProgress.start()
  const role = localStorage.getItem("TOKEN")
  const permiss = usePermissStore()
  if (!role && to.path !== "/login") {
    next("/login")
  } else if (to.meta.permiss && !permiss.keys.includes(to.meta.permiss)) {
    let powerValue =
      permiss.power == "super" ||
      permiss.power == "admin" ||
      permiss.power == "store"
    if (
      permiss.power == "store" &&
      (to.path == "/dashboard" || to.path == "/")
    ) {
      next("/storeInfo")
    } else {
      // 如果没有权限，则进入403
      next("/403")
    }
  } else {
    next()
  }
})

router.afterEach(() => {
  NProgress.done()
})

export default router
