<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      :collapse="sidebar.collapse"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <template v-for="item in items">
        <template v-if="item.subs">
          <el-sub-menu :index="item.index" :key="item.index" v-permiss="item.permiss">
            <template #title>
              <el-icon>
                <component :is="item.icon"></component>
              </el-icon>
              <span>{{ item.title }}</span>
            </template>
            <template v-for="subItem in item.subs" :key="subItem.index">
              <el-menu-item :index="subItem.index" v-permiss="item.permiss">
                {{ subItem.title }}
              </el-menu-item>
            </template>
          </el-sub-menu>
        </template>
        <template v-else>
          <el-menu-item :index="item.index" :key="item.index" v-permiss="item.permiss">
            <el-icon>
              <component :is="item.icon"></component>
            </el-icon>
            <template #title>{{ item.title }}</template>
          </el-menu-item>
        </template>
      </template>
    </el-menu>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useSidebarStore } from "../store/sidebar";
import { useRoute } from "vue-router";
import { usePermissStore } from "../store/permiss";
const permiss = usePermissStore();

const items = [
  {
    icon: "Odometer",
    index: "/dashboard",
    title: "首页",
    permiss: "1",
  },
  {
    icon: "Calendar",
    title: "商品管理",
    permiss: "2",
    subs:
      permiss.power == "store"
        ? [
            {
              index: "/supply-goods",
              title: "补货",
              permiss: "22",
            },
          ]
        : permiss.keys.includes("22")
        ? [
            {
              index: "/goods-table",
              title: "商品列表",
              permiss: "21",
            },
            {
              index: "/supply-goods",
              title: "补货",
              permiss: "22",
            },
          ]
        : [
            {
              index: "/goods-table",
              title: "商品列表",
              permiss: "21",
            },
          ],
  },
  {
    icon: "DocumentCopy",
    index: "/member-manage",
    title: "成员管理",
    permiss: "6",
    subs:
      permiss.power == "store"
        ? [
            {
              index: "/store-user",
              title: "店员列表",
              permiss: "61",
            },
          ]
        : [
            {
              index: "/member",
              title: "用户列表",
              permiss: "6",
            },
            {
              index: "/administrator",
              title: "管理员列表",
              permiss: "7",
            },
            {
              index: "/store-manager",
              title: "店长列表",
              permiss: "8",
            },
            {
              index: "/store-user",
              title: "店员列表",
              permiss: "61",
            },
          ],
  },
  {
    icon: "PieChart",
    index: permiss.power == "store" ? "/storeInfo" : "/store-management",
    title: "店铺管理",
    permiss: "9",
  },
  {
    icon: "Edit",
    index: "/orderinfo",
    title: "订单详情",
    permiss: "10",
  },
  {
    icon: "Warning",
    index: "/doings",
    title: "活动管理",
    permiss: "11",
  },
];

const route = useRoute();
const onRoutes = computed(() => {
  return route.path;
});

const sidebar = useSidebarStore();
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}

.sidebar::-webkit-scrollbar {
  width: 0;
}

.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}

.sidebar > ul {
  height: 100%;
}
</style>
