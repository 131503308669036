<template>
  <el-config-provider :locale="zhCn">
    <router-view />
  </el-config-provider>
</template>

<script setup lang="ts">
import { ElConfigProvider } from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
(window as any)._AMapSecurityConfig = {
    securityJsCode: "8a74675ec2442c282dee49beccf2144c",
  };
</script>
<style>
@import "./assets/css/main.css";
@import "./assets/css/color-dark.scss";

.success-text {
  color: #67c23a;
}

.danger-text {
  color: #f56c6c;
}

.primary-text {
  color: #409eff;
}
</style>
