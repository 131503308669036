import request from "../../utils/request"
enum API {
  createGoods_URL = "/createSysGoods",
  updateGoods_URL = "/updateSysGoodsInfo",
  getSysGoodsList_URL = "/getSysGoodsByType",
  deleteSysGoods_URL = "/deleteSysGoods",

  createSys_URL = "/createGoodsType",
  updateSys_URL = "/updateGoodsType",
  getSysGoodsType_URL = "/getSysGoodsTypeList",
  deleteGoodsType_URL = "/deleteGoodsType",
  replenishmentRequest_URL = "/replenishmentRequest",
}
export const createGoods = (data: any) =>
  request.post(API.createGoods_URL, data)
export const updateGoods = (data: any) =>
  request.post(API.updateGoods_URL, data)
export const createSys = (data: any) => request.post(API.createSys_URL, data)
export const updateSys = (data: any) => request.post(API.updateSys_URL, data)
export const getSysGoodsType = () => request.get(API.getSysGoodsType_URL)
export const getSysGoodsList = (goodsname?: string, shopid?: string) =>
  request.get(
    API.getSysGoodsList_URL +
      `?goodsname=${goodsname || ""}${shopid ? `&shopid=${shopid}` : ""}`
  )
export const deleteGoodsType = (id) =>
  request.get(API.deleteGoodsType_URL + `?typeid=${id}`)
export const deleteSysGoods = (id, p0?: string) =>
  request.get(API.deleteSysGoods_URL + `?goodsid=${id}`)

export const requestGoodslist = (data: any) =>
  request.post(API.replenishmentRequest_URL, data)

// 商品分类置顶
export const setTypeTop = (typeid, topflg) =>
  request.get("/setTypeTop" + `?typeid=${typeid}&topflg=${topflg}`)


// 编辑分类 商品顺序
export const updateGoodsPosition = (data: any) =>
  request.post("/updateGoodsOrTypePosition", data)