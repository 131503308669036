import request from "../../utils/request"
enum API {
  LOGIN_URL = "/webLogin",
  USERINFO_URL = "/getUserInfo",
  STORELISR_URL = "/getWebStoreList",
  GETUSERLIST_URL = "/getAllUserList",
  GDELSTOREUSER_URL = "/delShopClerk",
  setStoreuser_URL = "/createShopClerk",
  STOREUSERLISR_URL = "/getAllShopClerkList",
}
interface LoginInfo {
  phone: string
  password: string
}
// 登录
export const reqLogin = (data: LoginInfo) => request.post(API.LOGIN_URL, data)
// 获取用户信息
export const reqUserInfo = (uid) =>
  request.get(API.USERINFO_URL + `?uid=${uid}`)
// 获取点店长列表
export const getStoreList = (
  pageNum?: number,
  pageSize?: number,
  keyword?: string
) =>
  request.get(
    API.STORELISR_URL +
      `?pageNum=${pageNum || ""}&pageSize=${pageSize || ""}&keyword=${
        keyword || ""
      }`
  )

// 获取用户列表
export const getUserList = (
  pageNum?: number,
  pageSize?: number,
  phone?: string
) =>
  request.get(
    API.GETUSERLIST_URL +
      `?pageNum=${pageNum || ""}&pageSize=${pageSize || ""}&phone=${
        phone || ""
      }`
  )

// 获取店员列表
export const getStoreUserList = (
  pageNum?: number,
  pageSize?: number,
  keyword?: string
) =>
  request.get(
    API.STOREUSERLISR_URL +
      `?pageNum=${pageNum || ""}&pageSize=${pageSize || ""}&keyword=${
        keyword || ""
      }`
  )
// 删除店员
export const delStoreuser = (clerkid: string) =>
  request.get(API.GDELSTOREUSER_URL + `?clerkid=${clerkid}`)

export const setStoreuser = (data: any) =>
  request.post(API.setStoreuser_URL, data)
